.profile-main {
    min-height: 90vh;
    position: relative;
    margin: 2%;
}

.profile-pic{
    width: 70px;
    position: absolute;
    top: 0px;
    transform: translate(-50%, -50%);
    left: 50%;
}

.profile-main .center-div {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 10px 80px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 10px 30px 10px 10px;
    font-size: 12px;
    padding-top: 40px;

}

.profile-main .title {
    text-align: center;
    font-size: 20px;
}

.text-center .btn-light {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 10px 80px rgba(0, 0, 0, 0.12);
}

button.update {
    position: absolute;
    top: 10px;
    right: 10px;
}