.dashboard-card-container {
  height: 300px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(1px);
}

.dashboard-card-title {
  font-weight: bold;
  padding: 10px;
}

.dashboard-card-body {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 77%;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
}

.dashboard-card-title .add-new {
  position: absolute;
  top: 5px;
  right: 40px;
}

.card-info {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
}

.dashboard-card-title img {
  position: absolute;
  right: -13px;
  top: -10px;
  width: 50px;
}

.dashboard-card-body table tr {
  transition: all 0.1s;
  cursor: pointer;
}

.dashboard-card-body table tr:hover {
  background-color: rgba(242, 121, 53, 0.8);
  color: white;
}

.profile-icon {
  width: 40px;
}
