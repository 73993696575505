@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

*:not(i) {
  font-family: "Roboto Mono", monospace !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 0.48rem;
  padding-right: 2rem;
  color: #828282 !important;
  background-color: white;
}

.App {
  /* padding-top: 40px; */
  overflow-x: hidden;
  min-height: 100vh;
  background-image: url("/src/images/arab_tile.png");
}

.app-desktop-view {
  padding-left: 220px;
  padding-top: 40px;
}

.modal-header .close {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 0px 5px;
  font-size: 20px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  background-color: #dc143c;
  border-color: #dc143c;
}

.modal-header .close:hover {
  background-color: #b51333;
  border-color: #b51333;
}

.modal-dialog {
  padding-right: 13px;
}

button.shadow {
  box-shadow: 0px 12.5px 11.8px rgba(0, 0, 0, 0.03),
    0px 100px 94px rgba(0, 0, 0, 0.06);
}

button.btn-light {
  animation: all 1s;
  background-color: white;
}

button.btn-light:hover {
  border-color: #cdcdcd;
}

.btn-success {
  background-color: rgba(66, 186, 150, 0.8);
}

.bg-success {
  background-color: rgba(66, 186, 150, 0.8) !important;
  color: white;
}

.btn-info {
  background-color: rgba(13, 202, 240, 0.8);
  color: white;
}

.btn-info:hover {
  background-color: rgba(13, 202, 240, 1);
  color: white;
}

.btn-danger {
  background-color: rgba(223, 71, 89, 0.8);
}

.btn-warning {
  color: white;
  background-color: rgba(255, 193, 7, 0.8);
}

.btn-warning:hover {
  color: white;
  background-color: rgba(255, 193, 7, 1);
}

.btn-success,
.btn-danger,
.btn-warning,
.btn-info {
  border: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin !important;
  scrollbar-color: #9eb1db #ffffff !important;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px !important;
}

*::-webkit-scrollbar-track {
  background: #ffffff !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #9eb1db !important;
  border-radius: 10px !important;
  border: 3px solid #ffffff !important;
}

/* check box */
input[type="checkbox"] {
  appearance: none;
  width: 40px;
  height: 16px;
  border: 1px solid #aaa;
  border-radius: 2px;
  background: #ebebeb;
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  transition: all 0.3s;
  box-sizing: border-box;
}

input[type="checkbox"]:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 14px;
  height: 14px;
  background: white;
  border: 1px solid #aaa;
  border-radius: 2px;
  transition: left 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

input[type="checkbox"]:checked {
  background: #a6c7ff !important;
  border-color: #8daee5 !important;
}

input[type="checkbox"]:checked:after {
  left: 24px;
  border-color: #8daee5;
}

input[type="checkbox"]:hover:not(:checked):not(:disabled):after,
input[type="checkbox"]:focus:not(:checked):not(:disabled):after {
  left: 0px;
}

input[type="checkbox"]:hover:checked:not(:disabled):after,
input[type="checkbox"]:focus:checked:not(:disabled):after {
  left: 24px;
}

input[type="checkbox"]:disabled {
  opacity: 0.5;
}

/* sub-nav */
button.sub-nav {
  height: 35px;
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 0.3s;
  /* border: none; */
  border-width: 0px 0px 3px 0px;
  border-color: rgba(0, 0, 0, 0.3);
}

button.sub-nav.active {
  background-color: rgba(0, 153, 255, 0.6);
  color: white;
  border-width: 0px 0px 3px 0px;
  border-color: black;
  border-style: solid;
}

button.sub-nav:hover {
  background-color: rgba(0, 153, 255, 0.301);
}

button.sub-nav.w25 {
  width: 25%;
}

button.sub-nav.w33 {
  width: 33%;
}

button.sub-nav.w50 {
  width: 50%;
}

/* setting page */

.setting-card {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  position: absolute;
  height: 200px;
  width: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.2s;
  color: black;
}

.setting-card:hover {
  background-color: rgba(92, 233, 191, 0.6);
  color: white;
}

.setting-container {
  position: relative;
  height: 250px;
  transition: all 0.2s;
}

.setting-container:hover {
  transform: scale(1.1);
}

.setting-icon {
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 70px;
}

.setting-title {
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.bg-white60 {
  background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-1 {
  background-color: rgb(246, 246, 246);
}

.bg-white-2 {
  background-color: rgb(229, 229, 229);
}

.div-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.div-center-container {
  position: relative;
  width: 100%;
  height: 50vh;
}

.more-index {
  z-index: 10;
}

.less-index {
  z-index: 5;
}

.top-left-10 {
  top: 10px;
  left: 10px;
}

.pointer {
  cursor: pointer;
}

.three-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blur {
  backdrop-filter: blur(1px);
}

.light-onhover:hover {
  background-color: white;
}

.goog-te-banner-frame.skiptranslate,
.goog-gt-tt {
  display: none !important;
}

.w20 {
  width: 20%;
}


/* .graph-wrapper-wrapper {
  position: relative;
} */

.graph-scaled-wrapper {
  position: relative;
  z-index: 1;
}

.graph-scaled-content {
  box-sizing: border-box;
  display: inline-block;
  transform-origin: 0 0;
  z-index: -1;
}