.search-app {
    width: 48%;
    margin-left: 10px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.02), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.026), 0 10px 80px rgba(0, 0, 0, 0.12);
}



.application-container .row {
    width: 100%;
    padding: 10px;
}

.application-container .row .item {
    min-height: 100px;
    border-radius: 5px;
    box-shadow: 0 12.5px 5px rgba(0, 0, 0, 0.02), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 10px 10px rgba(0, 0, 0, 0.12);
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px)
}

.application-container .row div {
    margin-bottom: 10px;
}

.application-container .row .item div {
    position: relative;
}

.application-container .row .item div p:last-child {
    margin-bottom: 7px;
}

.application-container .row .item .icon {
    position: absolute;
    top: 10px;
    right: 5px;
    width: 70px;
    font-size: 30px;
    z-index: 30;
    cursor: pointer;
    /* border: 1px solid rgba(135, 135, 135, 0.5); */
    text-align: center;
    /* border-radius: 5px; */
    /* box-shadow: 0 0 50px #ccc; */


}

.application-container .row .item div .option {
    /* box-shadow: 0 0 50px #ccc; */
    border-radius: 5px;
    padding: 5px;
    text-align: center;

}

.application-container .row .item div .status {
    top: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* box-shadow: 0 0 50px #ccc; */
    margin-bottom: 40px;
}


.application-container .row .item div .active {
    color: green;
    border: 2px solid green;
    background-color: rgba(160, 255, 210, 0.4);
}

.modal-footer .row {
    width: 100%;
    text-align: center;
}

.modal-body .row [class*=col] {
    padding: 10px;
}

@media (max-width: 800px) {
    .search-app {
        width: 100% !important;
    }
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;

}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2196F3;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 5px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 5px;
}

input:checked+.slider {
    background-color: rgb(255, 55, 55);
}

/* input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
} */

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.app-title {
    /* -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset; */
    /* -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset; */
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.0) inset;

}