.smooth-box-shadow {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 10px 80px rgba(0, 0, 0, 0.12);
  padding: 7px;
  border-radius: 5px;
  position: relative;
  /* margin: 5px; */
}

.dashboard-main {
  width: 100%;
  position: relative;
}

.dashboard-main .card-info {
  min-height: 140px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1px);
  margin-bottom: 30px;
}

.dashboard-main .card-info .title {
  font-size: 12px;
  background-color: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.dashboard-main .card-info .icon {
  position: absolute;
  top: -15px;
  right: -10px;
}

.dashboard-main .card-info .icon img {
  width: 30px;
}

.dashboard-main .card-info .val {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.3em;
  text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.3);
}

img.weather {
  position: absolute;
  top: -40px;
  right: -25px;
}

.hide {
  display: none;
  line-height: 15.8px;
}

.show {
  display: block;
  line-height: 17.8px;
}

div.weather {
  cursor: pointer;
  font-size: 10px;
}

.weather-next {
  position: absolute;
  right: 15px;
  top: 45%;
}

button.danger-on-hover:hover {
  background-color: rgb(230, 68, 68);
  transition: all 1;
  color: white;
}

button.top-right {
  position: absolute;
  padding: 0px;
  width: 20px;
  top: 0;
  right: 0;
}

thead {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.coming-soon {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(1px);
  top: 0;
  left: 0;
  z-index: 10;
}

.coming-soon span {
  position: absolute;
  z-index: 11;
  top: 40%;
  left: 40%;
}
