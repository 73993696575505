.path {
    margin-top: 10px;
    margin-bottom: 20px;


}

.path span {
    margin-left: 3%;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06);
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    backdrop-filter: blur(1px)
}