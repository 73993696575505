.header-list {
    color: green !important;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.header-list:hover {
    color: orange !important;
}

.header-list-inner {
    padding: 3px;
}