.loader-container {
    position: fixed;
    display: flex;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(2px);
    z-index: 1100;
    justify-content: center;
    align-items: center;
}

.loader-wrapper {
    width: 200px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loader-circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    left: 15%;
    transform-origin: 50%;
    animation: loader-circle .5s alternate infinite ease;
    z-index: 1;

}

@keyframes loader-circle {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }

    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top: 0%;
    }
}

.loader-circle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
}

.loader-circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.loader-shadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    left: 15%;
    filter: blur(1px);
    animation: loader-shadow .5s alternate infinite ease;
    z-index: 0;
}

@keyframes loader-shadow {
    0% {
        transform: scaleX(1.5);
    }

    40% {
        transform: scaleX(1);
        opacity: .7;
    }

    100% {
        transform: scaleX(.2);
        opacity: .4;
    }
}

.loader-shadow:nth-child(4) {
    left: 45%;
    animation-delay: .2s
}

.loader-shadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.loader-wrapper span {
    position: absolute;
    top: 75px;
    font-family: 'Lato';
    font-size: 20px;
    letter-spacing: 12px;
    color: #fff;
    left: 15%;
}