.fdf-main {
  width: 100%;
  position: relative;
}

.rd {
  background-color: red;
}

.fdf-main .card-info {
  min-height: 140px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.4) !important;
  backdrop-filter: blur(2px);
  margin-bottom: 30px;
  max-width: 400px;
  cursor: pointer;
  transition: all 0.2s;
}

.fdf-main .card-info:hover {
  border: solid green;
}

.card-info .icon {
  position: absolute;
  top: -20px;
  right: -20px;
}

.card-info .icon img {
  width: 60px;
}
