.resetpass-main {
    height: 90vh;
    position: relative;
    font-family: 'Nunito', sans-serif;
}

.resetpass-main .center-div {
    position: absolute;
    top: 45%;
    left: 45%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    background-color: white;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 10px 80px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 10px;
}

.resetpass-main .title {
    text-align: center;
    font-size: 20px;
}