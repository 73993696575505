body {
    /* optinal */
    background-color: rgb(240, 240, 240);

    /* mandatory */
    margin-left: 0px;
    padding: 0px;
}

main {
    padding-left: 60px;
    padding-top: 20px;
}

.cos-font {
    font-family: 'Lobster', cursive;
}

.rm-text-decoration {
    color: inherit;
    text-decoration: inherit;
}

.rm-text-decoration:hover {
    color: inherit;
    text-decoration: inherit;
}

/* top nav start */
nav.top {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    top: 0px;
    right: 0px;
    background-color: white;
    z-index: 151;
    border: 1px silver solid;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 10.3px 17.9px rgba(0, 0, 0, 0.072), 0 20.8px 33.4px rgba(0, 0, 0, 0.046);
    border-radius: 0 0 10px 13px;
}

nav span.logo {
    /* color: white; */
    font-size: 20px;
    margin-left: 10px;
    padding-top: 5px;
    cursor: pointer;
}

nav span:last-child {
    margin-top: 10px;
    margin-right: 10px;
    /* color: white; */
    margin-left: auto;
    cursor: pointer;
}

/* top nav end */

/* side nav start */
nav.side-desktop {
    height: 100vh;
    top: 0px;
    left: 0px;
    width: 200px;
    display: flex;

}


nav.side-mobile {
    width: 100vw;
    top: 40px;
    left: 0px;
    padding-bottom: 20px;
    padding-top: 20px;
}

nav.side {
    padding-left: 5px;
    padding-right: 5px;
    position: fixed;
    background-color: white;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    box-shadow: 5px 0 5px 5px rgba(201, 201, 201, 0.334);
    z-index: 150;
    overflow-y: scroll;
}

nav.side a p {
    color: black;
    font-size: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

nav.side a p:hover {
    background-color: rgba(235, 160, 62, 0.6);
}

nav.side a p i {
    padding-right: 20px;
}

nav.side a p span {
    position: absolute;
    font-size: 10px;
    text-align: center;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    padding-left: 5px;
}

nav.side-desktop a:first-child {
    margin-top: 50px;
}

nav.side-desktop a:last-child {
    color: black;
    margin-top: auto;
}

/* side nav end */


div.nav-dropmenu {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    top: 40px;
    right: 5px;
    border-radius: 10px;
    backdrop-filter: blur(2px);
    padding: 5px;
}

div.nav-dropmenu img {
    width: 20px;
}

div.nav-dropmenu p {
    padding: 5px;
    border-radius: 5px;
}

div.nav-dropmenu p:hover {
    background-color: orange;
}